@charset "utf-8";
/* CSS Document */


/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  header  +++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

.cart_h_b_01{ width:70px; z-index:99999; position:fixed; top:20px; right:20px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border-radius: 35px;        /* CSS3草案 */  
	-webkit-border-radius: 35px;    /* Safari,Google Chrome用 */  
	-moz-border-radius: 35px;   /* Firefox用 */ 
}
.cart_h_b_01 img{ width:100%;}


#header_01{
	background:url(https://cdn.shopify.com/s/files/1/0719/8415/2872/files/img_bg.jpg);
	margin:0px 0 0 0;
}
#header_01 .inner_01{
	width:1200px;
	height:199px;
	position:relative;
}

#header_01 .inner_01 a{	position:absolute; display:block;}

#header_01 .inner_01 a.h_link_01{
	width:16.5%; height:100%;
	top:0%; left:0%;
}
#header_01 .inner_01 a.h_link_02{
	width:9%; height:35%;
	top:19%; left:30%;
}
#header_01 .inner_01 a.h_link_03{
	width:11%; height:31%;
	top:62%; left:60.5%;
}
#header_01 .inner_01 a.h_link_04{
	width:12%; height:31%;
	top:62%; left:71.5%;
}
#header_01 .inner_01 a.h_link_05{
	width:14%; height:31%;
	top:62%; left:84%;
}




#header_01 .inner_01 a{
	position:absolute;
	display:block;
	background:#fff;
	filter:alpha(style=0, opacity=0);
	-moz-opacity:0;
	opacity:0;
}

#header_01 .inner_01 a:hover{
	filter:alpha(style=0, opacity=20);
	-moz-opacity:0.2;
	opacity:0.2;
}









